import React from 'react';
import { styled } from 'styled-components';

interface Props {
    isMobile: boolean,
    goToLoginPage: () => void,
    inquiry: any,
}

export default function NotImplemented ({ isMobile, goToLoginPage, inquiry }: Props) {
    return (
        <div>
            <Border isMobile={isMobile}>
                <Title isMobile={isMobile}>501</Title>
                <Title2 isMobile={isMobile}>Not Implemented</Title2>
                
                <Content isMobile={isMobile}>申し訳ございません。サーバーで問題が発生しました。エラーが繰り返し発生する場合は、お手数ですが下記のお問い合わせ先よりご連絡ください。</Content>

                <Line isMobile={isMobile} />
                
                <Content3 isMobile={isMobile}>お問合せ先</Content3>
                <Content2 isMobile={isMobile}>
                    {isMobile ? 
                        inquiry ? inquiry.name : ''
                    :
                        inquiry ? inquiry.name : ''
                    }
                </Content2>
                <Content2 isMobile={isMobile}>
                    TEL: {isMobile ? 
                        inquiry ? inquiry.telephone : ''
                    :
                        inquiry ? inquiry.telephone : ''
                    }
                </Content2>
                <Content2 isMobile={isMobile}>
                    E-Mail: {isMobile ? 
                        inquiry ? inquiry.email : ''
                    :
                        inquiry ? inquiry.email : ''
                    }
                </Content2>
                <Content2 isMobile={isMobile}>
                    {isMobile ? 
                        inquiry ? inquiry.remarks : ''
                    :
                        inquiry ? inquiry.remarks : ''
                    }
                </Content2>
            </Border>
            <ButtonReturn onClick={goToLoginPage} isMobile={isMobile}>TOPページに戻る</ButtonReturn>
        </div>
    )
}

const Border = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    border: '1px solid #46965A',
    width: isMobile ? '350px' : '750px',
    height: 'auto',
    padding: '20px',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 195px);' : 'calc(50% - 395px);',
    marginTop: '50px'
}));

const Title = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#46965A',
    fontWeight: 'bold',
    fontSize: 40,
    textAlign: 'center',
    marginBottom: '0px',
    margin: 0
}));

const Title2 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#46965A',
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'center',
    marginTop: '0px'
}));

const Content = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#000000',
    fontWeight: 'normal',
    fontSize: isMobile ? 14 : 20,
    textAlign: 'justify',
    marginTop: '40px',
    paddingRight: isMobile ? '10px' : '20px',
    paddingLeft: isMobile ? '10px' : '20px',
}));

const Line = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    borderTop: '1px solid #46965A',
    marginTop:'-10px',
    marginBottom: '10px',
    marginLeft: isMobile ? '10px' : '20px',
    marginRight: isMobile ? '10px' : '20px',
}));

const Content2 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontWeight: 'regular',
    fontSize: isMobile ? 12 : 16,
    margin: 0,
    paddingRight: isMobile ? '10px' : '20px',
    paddingLeft: isMobile ? '10px' : '20px',
}));

const Content3 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#000000',
    fontWeight: 'bold',
    fontSize: isMobile ? 14 : 16,
    margin: 0,
    paddingRight: isMobile ? '10px' : '20px',
    paddingLeft: isMobile ? '10px' : '20px',
}));

const ButtonReturn = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: '#808080',
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: isMobile ? '206px' : '249px',
    height: isMobile ? '56px' : '64px',
    borderRadius: '10px',
    border: 'none',
    marginTop: isMobile ? '20px' : '30px',
    marginBottom: '20px',
    position: 'relative',
    marginLeft: isMobile ? 'calc(50% - 103px)' : 'calc(50% - 124px)',
    cursor: 'pointer',
}));