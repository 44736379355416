import React, { useState } from 'react';
import { styled } from 'styled-components';
import { Grid } from '@mui/material';
import { orange, darkGreen } from '../StyleVariables';
import { Title as TitleCommon, DetailPopUp, Image as ImageCommon, SubTitle, GridCommon, Spacer, GridWhite , formatZipcode, /*OutsideImage*/} from './../components/Common';
import { AspectRatio } from '@mui/joy';

interface GiftProps {
    isMobile: boolean,
    name: string,
    link: string,
    setOpenDetail: (id: string) => void,
    id: any,
}

function Gift ( { isMobile, name, link, setOpenDetail, id, }: GiftProps ) {

    return (
        <GiftContainer isMobile={isMobile} container item xs={isMobile ? 12 : 6} alignItems="center" >
            <Grid container item xs={12} style={{height:'75px', alignItems: 'center'}}>
                {name}
            </Grid>
            <Grid item xs={12}>
                <AspectRatio ratio={1} style={{width: '100%', border: '1px solid black'}}>
                {/* <OutsideImage isMobile={isMobile}> */}
                    <Image isMobile={isMobile} src={link} alt="product" />
                {/* </OutsideImage> */}
                </AspectRatio>
            </Grid>
            <Grid item xs={12}>
                <OrangeButton onClick={() => { setOpenDetail(id) }} isMobile={isMobile} >
                    詳細を見る
                </OrangeButton>
            </Grid>
        </GiftContainer>
    )
}

interface Props {
    isMobile: boolean,
    goToLoginPage: () => void,
    user: any,
    inquiry: any,
}

export default function Home ({ isMobile, goToLoginPage, user, inquiry }: Props) {
    const special = user ? user.special : null;
    const [ openDetail, setOpenDetail ] = useState<string|null>(null);

    return (
        <div>
            <Spacer/>
            <Parent container justifyContent="center">
                <Outside item isMobile={isMobile}>
                    <TitleCommon isMobile={isMobile}>
                        株主優待のお申込みを頂き誠にありがとうございました。下記の内容にて受付を完了しております。ご優待品の発送は2024年12月を予定しております。今しばらくお時間をいただきますようお願い申し上げます。
                    </TitleCommon>
                    
                    {/* LIST BENEFITS */}
                    {/* <Border isMobile={isMobile}> */}
                        {/* <Box isMobile={isMobile}>
                            <Title2 isMobile={isMobile}>ご優待品</Title2>
                        </Box> */}
                        <GridGreen isMobile={isMobile} item xs={12}>ご優待品</GridGreen>
                        {special ?
                            <Grid container>
                                <GridWhite container item xs={12}>
                                    <Gift
                                        isMobile={isMobile}
                                        name={special.item.name}
                                        link={special.item.image}
                                        setOpenDetail={setOpenDetail}
                                        id="special_item"
                                    />

                                    {isMobile ? 
                                        <Liner isMobile={isMobile} />
                                    :
                                        null
                                    }
                                    
                                    <Gift
                                        isMobile={isMobile}
                                        name={user.item.name}
                                        link={user.item.image}
                                        setOpenDetail={setOpenDetail}
                                        id="item"
                                    />
                                </GridWhite>
                            </Grid>
                        :
                            <Grid container>
                                <GridWhite container item xs={12}>
                                    <Inside isMobile={isMobile}>
                                        <Content isMobile={isMobile}>
                                            {user.item.name}
                                        </Content>
                                        <ContentImg 
                                            src = {user.item.image}
                                            isMobile={isMobile}
                                        />
                                        <ButtonDetails onClick={() => { setOpenDetail("item") }} isMobile={isMobile}>詳細を見る</ButtonDetails>
                                    </Inside>
                                </GridWhite>
                            </Grid>
                        }
                    {/* </Border> */}

                    <Spacer/>

                    {/* DETAIL ADDRESS */}
                    <Grid container>
                    {/* <Border isMobile={isMobile}> */}
                        <GridGreen isMobile={isMobile} item xs={12}>送付先</GridGreen>
                        {/* <Box isMobile={isMobile}>
                            <Title2 isMobile={isMobile}>送付先</Title2>
                        </Box> */}
                        <GridWhite container>
                            <Inside2 isMobile={isMobile}>
                                <Grid container>
                                    <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>お名前</GridTitle>
                                    <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{user.name}</GridContent>
                                </Grid>

                                <Liner isMobile={isMobile} />

                                <Grid container>
                                    <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>郵便番号</GridTitle>
                                    <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{formatZipcode(user.zipcode)}</GridContent>
                                </Grid>

                                <Liner isMobile={isMobile} />

                                <Grid container>
                                    <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>都道府県</GridTitle>
                                    <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{user.prefecture.name}</GridContent>
                                </Grid>

                                <Liner isMobile={isMobile} />

                                <Grid container>
                                    <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>市区町村</GridTitle>
                                    <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{user.address1}</GridContent>
                                </Grid>

                                <Liner isMobile={isMobile} />

                                <Grid container>
                                    <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>丁番地</GridTitle>
                                    <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{user.address2}</GridContent>
                                </Grid>

                                <Liner isMobile={isMobile} />

                                <Grid container>
                                    <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>マンション・ビル名</GridTitle>
                                    <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{!user.address3 ? '-' : user.address3}</GridContent>
                                </Grid>

                                <Liner isMobile={isMobile} />

                                <Grid container>
                                    <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>電話番号</GridTitle>
                                    <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{user.phone_number1}-{user.phone_number2}-{user.phone_number3}</GridContent>
                                </Grid>
                                
                                <Liner isMobile={isMobile} />

                                <Grid container>
                                    <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>メールアドレス</GridTitle>
                                    <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{user.email}</GridContent>
                                </Grid>
                            </Inside2>
                        </GridWhite>
                    {/* </Border> */}
                    </Grid>

                    <Spacer/>

                    {/* CONTACT INFORMATION */}
                    <Grid container>
                    {/* <Border isMobile={isMobile}> */}
                        <GridGreen isMobile={isMobile} item xs={12}>お問合せ先</GridGreen>
                        {/* <Box isMobile={isMobile}>
                            <Title2 isMobile={isMobile}>お問合せ先</Title2>
                        </Box> */}
                        <GridWhite container>
                            <Inside3 isMobile={isMobile}>
                                <Content2 isMobile={isMobile}>
                                    {isMobile ? 
                                        inquiry ? inquiry.name : ''
                                    :
                                        inquiry ? inquiry.name : ''
                                    }
                                </Content2>
                                <Content2 isMobile={isMobile}>
                                    TEL: {isMobile ? 
                                        inquiry ? inquiry.telephone : ''
                                    :
                                        inquiry ? inquiry.telephone : ''
                                    }
                                </Content2>
                                <Content2 isMobile={isMobile}>
                                    E-Mail: {isMobile ? 
                                        inquiry ? inquiry.email : ''
                                    :
                                        inquiry ? inquiry.email : ''
                                    }
                                </Content2>
                                <Content2 isMobile={isMobile}>
                                    {isMobile ? 
                                        inquiry ? inquiry.remarks : ''
                                    :
                                        inquiry ? inquiry.remarks : ''
                                    }
                                </Content2>
                            </Inside3>
                        </GridWhite>
                    {/* </Border> */}
                    </Grid>
                    <ButtonReturn onClick={goToLoginPage} isMobile={isMobile}>ログイン画面に戻る</ButtonReturn>
                </Outside>
            </Parent>
            {openDetail &&
                <DetailPopUp
                    isOpen={(openDetail !== null) ? true : false}
                    close={() => setOpenDetail(null)}
                    isMobile={isMobile}
                >
                    <GridCommon item xs={isMobile ? 12 : 6}>
                        {/* <OutsideImage isMobile={isMobile} > */}
                        <AspectRatio ratio={1} style={{width: '100%', border: '1px solid black'}}>
                            <ImageCommon src={openDetail === "special_item" ? special.item.image : user.item.image} alt="default_product" isMobile={isMobile}/>
                        {/* </OutsideImage> */}
                        </AspectRatio>
                    </GridCommon>
                    <GridCommon item xs={isMobile ? 12 : 6}>
                        <TitleCommon isMobile={isMobile}>
                            {openDetail === "special_item" ? special.item.name : user.item.name}
                        </TitleCommon>
                        <SubTitle isMobile={isMobile}>
                            {openDetail === "special_item" ? special.item.detail : user.item.detail}
                        </SubTitle>
                    </GridCommon>
                </DetailPopUp>
            }
        </div>
    )
}

// LIST BENEFIT CSS
const Parent = styled(Grid)(() => ({
    width: '100vw'
}));

const Outside = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    width: '100%',
    maxWidth: '850px',
    padding: '10px',
    paddingTop: '0px',
    paddingBottom: '40px'
}));

// const Title = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
//     color: 'black',
//     fontWeight: 'bold',
//     fontSize: isMobile ? 20 : 24,
//     textAlign: 'left'
// }));

// const Border = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
//     border: '1px solid #46965A',
//     width: isMobile ? '350px' : '850px',
//     height: 'auto',
//     margin: '0px',
//     marginBottom: '30px'
// }));

// const Box = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
//     backgroundColor: '#46965A',
//     width: isMobile ? '350px' : '850px',
//     height: '50px',
//     marginTop: isMobile ? '-16px' : '-24px'
// }));

const GridGreen = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: darkGreen,
    fontSize: isMobile ? 16 : 24,
    padding: '0px',
    margin: '0px',
    textAlign: 'center',
    fontWeight: 'bold',
    height: 'auto'
}));

// const Title2 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
//     color: 'white',
//     fontWeight: 'bold',
//     fontSize: isMobile ? 16 : 24,
//     textAlign: 'center',
//     paddingTop: '5px'
// }));

const Inside = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    width: isMobile ? '293px' : '500px',
    height: 'auto',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 146px);' : 'calc(50% - 250px);',
    marginTop: '10px'
}));

const Content = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#000000',
    fontWeight: isMobile ? 'bold' : 'medium',
    fontSize: isMobile ? 16 : 24,
}));

const ContentImg = styled("img")<{isMobile: boolean}>(({isMobile}) => ({
    width: isMobile ? '300px' : '500px',
    height: isMobile ? '300px' : '500px'
}));

// const GridWhite = styled(Grid)(() => ({
//     padding: '5px 10px 0px 10px',
//     margin: '0px',
//     height: 'auto'
// }));

const GiftContainer = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    padding: '10px',
    fontWeight: 600,
    fontSize: isMobile ? 16 : 24
}));

const Image = styled("img")<{isMobile: boolean}>(({isMobile}) => ({
    width: '100%',
    objectFit: "contain",
    objectPosition: "center center"
}));

const OrangeButton = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: orange,
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: '100%',
    height: isMobile ? '34px' : '48px',
    borderRadius: '10px',
    border: 'none',
    marginTop: '15px',
    marginBottom: '20px',
    cursor: 'pointer'
}));

const ButtonDetails = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: '#EE7948',
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: isMobile ? '300px' : '500px',
    height: isMobile ? '34px' : '50px',
    borderRadius: '10px',
    border: 'none',
    marginTop: '20px',
    marginBottom: '20px',
    cursor: 'pointer'
}));

// DETAIL ADDRESS
const Inside2 = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    width: isMobile ? '318px' : '850px',
    height: 'auto',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 159px);' : 'calc(50% - 408px);',
    marginTop: '10px',
    paddingBottom: '10px'
}));

const GridTitle = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontSize: isMobile ? 12 : 24,
    fontWeight: isMobile ? 'regular' : 'bold',
    padding: '5px 10px'
}));

const GridContent = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontSize: isMobile ? 16 : 24,
    padding: '5px 10px'
}));

const Liner = styled('hr')<{isMobile: boolean}>(({isMobile}) => ({
    border: '1px solid #46965A',
    width: '100%',
}));

// CONTACT INFORMATION CSS
const Inside3 = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    width: isMobile ? '310px' : '850px',
    height: 'auto',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 155px);' : 'calc(50% - 408px);',
    // marginTop: '10px',
    marginBottom: '4px',
    fontSize: 16,
    fontWeight: 600,
    padding: '10px'
}));

const Content2 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontWeight: 'regular',
    fontSize: isMobile ? 12 : 16,
    margin: '2px 0 0 0'
}));

const ButtonReturn = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: '#808080',
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: isMobile ? '350px' : '500px',
    height: isMobile ? '50px' : '80px',
    borderRadius: '10px',
    border: 'none',
    marginTop: isMobile ? '20px' : '30px',
    marginBottom: '40px',
    position: 'relative',
    marginLeft: isMobile ? 'calc(50% - 175px)' : 'calc(50% - 250px)',
    cursor: 'pointer',
}));