import React from 'react';
import { styled } from 'styled-components';
import { Grid } from '@mui/material';

interface Props {
    children: any
}

export default function PageLayout ({ children }: Props) {

    return (
        <Parent container justifyContent="center">
            <Child item>
                {children}
            </Child>
        </Parent>
    )
}

const Parent = styled(Grid)(() => ({
    width: '100vw'
}));

const Child = styled(Grid)(() => ({
    width: '100%',
    maxWidth: '850px',
    padding: '10px',
    paddingTop: '0px',
    paddingBottom: '40px'
}));