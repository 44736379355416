import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Checkbox, CircularProgress, Grid, FormGroup, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { styled } from 'styled-components';
import { CityChoices, convertToHalfWidth, DarkGreenButton, ErrorPopup, GridWhite, Spacer, SubTitle, Title } from '../../components/Common';
import { blue, gray, orange, red } from '../../StyleVariables';
import AlertError from '../AlertError';
import { CheckBox } from '@mui/icons-material';

interface InputRowProps {
    isMobile: boolean,
    name: string,
    value: string | string[],
    tempValue: string | string[],
    handleChange: (e: any) => void,
    handleCheckZipCode: (e: any) => void,
    prefectures: any,
    loadingZipcode: any,
    isError: any,
    isErrorLength: any,
    isErrorRegex: any,
    handleBlur: (e: any) => void,
    // handleBlurPhoneNumber: (e: any) => void,
}

const InputRow = ({ isMobile, name, value, handleChange, handleCheckZipCode, prefectures, loadingZipcode, isError, isErrorLength, isErrorRegex, handleBlur, tempValue, }: InputRowProps) => {
    let title = '';
    let placeholder = '';
    switch(name){
        case 'name': title = 'お名前'; placeholder = '例）山田太郎'; break;
        case 'zipcode': title = '郵便番号'; placeholder = '例）1500031'; break;
        case 'prefecture_id': title = '都道府県'; placeholder = '選択してください'; break;
        case 'address1': title = '市区町村'; placeholder = '例）○○市○○区○○町'; break;
        case 'address2': title = '丁番地'; placeholder = '例）○○1-2-3'; break;
        case 'address3': title = 'マンション・ビル名'; placeholder = '例）○○マンション 101号'; break;
        case 'phone_number': title = '電話番号'; break;
        case 'email': title = 'メールアドレス'; placeholder = '例）○○○@example.com'; break;
        default: break;
    }

    return (
        <GridForm isMobile={isMobile} container>
            <Grid item xs={isMobile ? 12 : 4}>
                <FormTitle isMobile={isMobile}>{title}{name === 'address3' ? '': <Star>*</Star>}</FormTitle>
            </Grid>
            <Grid container item xs={isMobile ? 12 : 8} alignItems="center" justifyContent="flex-start">
                {
                    name === 'zipcode' ?
                        <Grid container item xs={12}>
                            <Grid item xs={isMobile ? 6 : 5}>
                                <TextInput required smaller={true} maxLength={7} isMobile={isMobile} name={name} onChange={handleChange} onBlur={handleBlur} value={value} placeholder={placeholder} />
                            </Grid>
                            <Grid item xs={isMobile ? 6 : 5}>
                                {loadingZipcode ? 
                                    <OrangeButton isMobile={isMobile} disabled={loadingZipcode ? true : false}>
                                        <CircularProgress size="25px" style={{color: 'white', marginLeft: '20px'}}/>
                                    </OrangeButton>
                                :
                                    <OrangeButton isMobile={isMobile} onClick={handleCheckZipCode}>住所自動入力</OrangeButton>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {(!value && isError) ? <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>郵便番号を入力してください。</p> : null}
                                {(value && isErrorLength) ? <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>郵便番号は7字以内で入力してください。</p> : null}
                                {isErrorRegex ? 
                                    <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>「{tempValue}」はご入力いただけません。</p> 
                                :
                                    null
                                }
                            </Grid>
                        </Grid>
                    :
                    name === 'prefecture_id' ?
                        <SelectGrid container item xs={12} isMobile={isMobile}>
                            <SelectWrapper isMobile={isMobile} className="select-wrapper">
                                <SelectInput isMobile={isMobile} required onChange={handleChange} name={name} value={value}>
                                    <option value="" disabled>選択してください</option>
                                    {prefectures.map((pref: any, i: any) => (
                                        <option key={"pref-"+i} value={pref.id}>{pref.name}</option>
                                    ))}
                                </SelectInput>
                            </SelectWrapper>
                        </SelectGrid>
                    :
                    name === 'phone_number' ?
                    <Grid container item xs={12} style={{marginTop: '10px'}}>
                        <Grid container item xs={12}>
                            {/* {[1,2,3].map((i, idx) => (
                                <Grid container xs={12}>
                                    <Grid item xs={isMobile ? 4 : 4}>
                                        <TextInput required smaller={true} key={'phone-'+i} isMobile={isMobile} name={'phone_number_' + i} onChange={handleChange} onBlur={handleBlurPhoneNumber} value={value[idx]} placeholder={i === 1 ? '例）000' : '0000'} />
                                    </Grid>
                                </Grid>
                            ))} */}
                            
                            <Grid container xs={12}>
                                <Grid item xs={isMobile ? 3 : 3}>
                                    <TextInputNumber pattern='[0-9]*' inputMode="numeric" required smaller={true} isMobile={isMobile} name='phone_number_1' onChange={handleChange} onBlur={handleBlur} value={value[0]} placeholder={'例）000'} />
                                </Grid>
                                <Grid item xs={isMobile ? 1 : 1} style={{alignItems: 'center'}}>
                                    <p style={{textAlign: 'center', paddingRight: '10px', marginTop: '10px'}}>-</p>
                                </Grid>
                                <Grid item xs={isMobile ? 3 : 3}>
                                    <TextInputNumber pattern='[0-9]*' inputMode="numeric" required smaller={true} isMobile={isMobile} name='phone_number_2' onChange={handleChange} onBlur={handleBlur} value={value[1]} placeholder={'0000'} />
                                </Grid>
                                <Grid item xs={isMobile ? 1 : 1} style={{alignItems: 'center'}}>
                                    <p style={{textAlign: 'center', paddingRight: '10px', marginTop: '10px'}}>-</p>
                                </Grid>
                                <Grid item xs={isMobile ? 3 : 3}>
                                    <TextInputNumber pattern='[0-9]*' inputMode="numeric"required smaller={true} isMobile={isMobile} name='phone_number_3' onChange={handleChange} onBlur={handleBlur} value={value[2]} placeholder={'0000'} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {(!(value[0] && value[1] && value[2]) && isError) ? <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>電話番号を入力してください。</p> : null}
                            {(((value[0] && value[1] && value[2]) && isErrorLength) || isErrorRegex) ? <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>電話番号の形式が正しくありません。</p> : null}
                            {/* {isErrorRegex ? 
                                // <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>「{tempValue}」はご入力いただけません。</p> 
                                <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>電話番号の形式が正しくありません。</p> 
                            :
                                null
                            } */}
                        </Grid>
                    </Grid>
                    :
                        <Grid container item xs={12}>
                            <TextInput required={(name === 'address3') ? false : true} onChange={handleChange} onBlur={handleBlur} isMobile={isMobile} name={name} value={value} placeholder={placeholder} />
                            {(!value && isError) ?
                                    name === 'name' ?
                                        <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>お名前を入力してください。</p> 
                                    :
                                    name === 'address1' ?
                                        <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>市区町村を入力してください。</p> 
                                    :
                                    name === 'address2' ?
                                        <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>丁番地を入力してください。</p> 
                                    :
                                    name === 'address3' ?
                                        <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>マンション・ビル名を入力してください。</p> 
                                    :
                                    name === 'email' ?
                                        <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>メールアドレスを入力してください。</p> 
                                    :
                                        <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>入力を確認してください。</p> 
                                : 
                                null
                            }
                            {(value && isErrorLength) ? 
                                name === 'name' ?
                                    <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>お名前は16字以内で入力してください。</p> 
                                :
                                name === 'address1' ?
                                    <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>市区町村は12字以内で入力してください。</p> 
                                :
                                name === 'address2' ?
                                    <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>丁番地は16字以内で入力してください。</p> 
                                :
                                name === 'address3' ?
                                    <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>マンション・ビル名は16字以内で入力してください。</p> 
                                :
                                name === 'email' ?
                                    <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>メールアドレスは255字以内で入力してください。</p> 
                                :
                                    <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>入力を確認してください。</p> 
                            : 
                                null
                            }
                            {isErrorRegex ? 
                                name === 'email' ?
                                    <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>メールアドレスの形式が正しくありません。</p> 
                                :
                                    <p style={{color: '#E80000', fontSize:'14px', margin: '5px 0 0 0'}}>「{tempValue}」はご入力いただけません。</p> 
                            :
                                null
                            }
                        </Grid>
                }
            </Grid>
        </GridForm>
    )
}

interface Props {
    isMobile: boolean,
    goToNextPage: () => void,
    goToPrevPage: () => void,
    prefectures: any,
    values: any,
    setValues: (values: any) => void,
}

export default function Step2 ({ isMobile, goToNextPage, goToPrevPage, prefectures, values, setValues }: Props) {

    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [loadingZipcode, setLoadingZipcode] = useState(false);
    const [errorMessage, setError] = useState("");
    const [cityChoices, setCityChoices] = useState<any|null>(null);
    const [tempValues, setTempValues] = useState<any>({
        name: values.name,
        zipcode: values.zipcode,
        prefecture_id: values.prefecture_id,
        address1: values.address1,
        address2: values.address2,
        address3: values.address3,
        phone_number_1: values.phone_number[0],
        phone_number_2: values.phone_number[1],
        phone_number_3: values.phone_number[2],
        email: values.email
    })
    const [errorValues, setErrorValues] = useState<any>({
        name: false,
        zipcode: false,
        prefecture_id: false,
        address1: false,
        address2: false,
        address3: false,
        phone_number_1: false,
        phone_number_2: false,
        phone_number_3: false,
        email: false
    });
    const [errorRegex, setErrorRegex] = useState<any>({
        name: false,
        zipcode: false,
        prefecture_id: false,
        address1: false,
        address2: false,
        address3: false,
        phone_number_1: false,
        phone_number_2: false,
        phone_number_3: false,
        email: false
    });
    const [errorLength, setErrorLength] = useState<any>({
        name: false,
        zipcode: false,
        prefecture_id: false,
        address1: false,
        address2: false,
        address3: false,
        phone_number_1: false,
        phone_number_2: false,
        phone_number_3: false,
        email: false
    });
    const [errorNotFound, setErrorNotFound] = useState(false);
    const [phoneNumberTotalError, setPhoneNumberTotalError] = useState(false);

    const handleChange = (e: any) => {
        const value = e.target.value;

        if(e.target.name === 'phone_number_1' || e.target.name === 'phone_number_2' || e.target.name === 'phone_number_3'){
            let oldPhoneNumber = values.phone_number;
            switch(e.target.name){
                case 'phone_number_1': oldPhoneNumber[0] = String(value); break;
                case 'phone_number_2': oldPhoneNumber[1] = String(value); break;
                case 'phone_number_3': oldPhoneNumber[2] = String(value); break;
                default: break;
            }
            setValues({
                ...values,
                'phone_number': oldPhoneNumber
            });

            const hasAnyError = checkError(e.target.name, value);

            setErrorValues({
                ...errorValues,
                [e.target.name]: hasAnyError.isErrorEmpty
            })
            setErrorLength({
                ...errorLength,
                [e.target.name]: hasAnyError.isErrorLength
            })
            setErrorRegex({
                ...errorRegex,
                [e.target.name]: hasAnyError.isErrorRegex
            })

            if(hasAnyError.isErrorRegex){
                setTempValues({
                    ...tempValues,
                    [e.target.name]: value
                })
            } else {
                setTempValues({
                    ...tempValues,
                    [e.target.name]: null
                })
            }

            let totalLength = 0;
            if(e.target.name === 'phone_number_1'){
                totalLength = (value.length + oldPhoneNumber[1].length + oldPhoneNumber[2].length)
            } else if (e.target.name === "phone_number_2") {
                totalLength = (oldPhoneNumber[0].length + value.length + oldPhoneNumber[2].length)
            } else if (e.target.name === "phone_number_3"){
                totalLength = (oldPhoneNumber[0].length + oldPhoneNumber[1].length + value.length)
            }
            if (totalLength < 10 || totalLength > 11) {
                setPhoneNumberTotalError(true)
            } else {
                setPhoneNumberTotalError(false)
            }
        } else {
            setValues({
                ...values,
                [e.target.name]: String(value)
            });
        }
    }

    type Names = "name" | "zipcode" | "address1" | "address2" | "address3" | "email" | "prefecture_id" | "phone_number_1" | "phone_number_2" | "phone_number_3"

    const checkError = (name: Names, value: string) => {
        const regexes = {
            "name": /[^A-Z a-z Ａ-ｚぁ-んァ-ン一-龥ーぁ-ゔゞァ-・ヽヾ゛゜ー々ゝゞ（）髙﨑塚德濵栁瀨隆𠮷　0-9]/g,
            "zipcode": /[^0-9]/g,
            "prefecture_id": /[^0-9]/g,
            "address1": /[^A-Z a-z Ａ-ｚぁ-んァ-ン一-龥ーぁ-ゔゞァ-・ヽヾ゛゜ー々ゝゞ（）髙﨑塚德濵栁瀨隆𠮷　0-9 -]/g,
            "address2": /[^A-Z a-z Ａ-ｚぁ-んァ-ン一-龥ーぁ-ゔゞァ-・ヽヾ゛゜ー々ゝゞ（）髙﨑塚德濵栁瀨隆𠮷　0-9 -]/g,
            "address3": /[^A-Z a-z Ａ-ｚぁ-んァ-ン一-龥ーぁ-ゔゞァ-・ヽヾ゛゜ー々ゝゞ（）髙﨑塚德濵栁瀨隆𠮷　0-9 -]/g,
            "email":  /^[a-zA-Z0-9._%+-]+@[\w%\+\-]+(\.[\w%\+\-]+)+$/,
            "phone_number_1": /[^0-9]/g,
            "phone_number_2": /[^0-9]/g,
            "phone_number_3": /[^0-9]/g,
        }

        const minLength = {
            "name": 1,
            "zipcode": 1,
            "prefecture_id": 1,
            "address1": 1,
            "address2": 1,
            "address3": 0,
            "phone_number_1": 2,
            "phone_number_2": 1,
            "phone_number_3": 4,
            "email": 1
        }

        const maxLength = {
            "name": 16,
            "zipcode": 7,
            "prefecture_id": 2,
            "address1": 12,
            "address2": 16,
            "address3": 16,
            "phone_number_1": 5,
            "phone_number_2": 4,
            "phone_number_3": 4,
            "email": 255
        }

        const emptyCheck = {
            "name": true,
            "zipcode": true,
            "prefecture_id": true,
            "address1": true,
            "address2": true,
            "address3": false,
            "phone_number_1": true,
            "phone_number_2": true,
            "phone_number_3": true,
            "email": true
        }

        let isErrorEmpty = false;
        let isErrorRegex = false;
        let isErrorLength = false;
        let nonMatchingChars = "";

        // check if empty
        if(emptyCheck[name]){
            if(!value) isErrorEmpty = true;
        }

        // check if regex wrong
        if(regexes[name]){
            if(name === "email") {
                if(isErrorEmpty){
                    isErrorRegex=false;
                } else{
                    if(!regexes[name].test(value)){
                        isErrorRegex = true;
                        nonMatchingChars = value
                    }
                }
            } else {
                const matches = value.match(regexes[name]);
                if(matches) {
                    isErrorRegex = true;
                    nonMatchingChars = matches.join("");
                }
            }
        }

        // console.log(value.length, (value.length < minLength[name]), (value.length > maxLength[name]));

        // check if min wrong
        if(value.length < minLength[name]){
            isErrorLength = true;
        }

        // check if max wrong
        if(value.length > maxLength[name]){
            isErrorLength = true;
        }

        return {
            isErrorEmpty,
            isErrorRegex,
            isErrorLength,
            nonMatchingChars
        }
    }

    const handleBlur = (e: any) => {
        const name = e.target.name;
        const value = convertToHalfWidth(e.target.value);
        const hasAnyError = checkError(name, value);

        if(name === 'phone_number_1' || name === 'phone_number_2' || name === 'phone_number_3'){
            let oldPhoneNumber = values.phone_number;
            switch(name){
                case 'phone_number_1': oldPhoneNumber[0] = String(value); break;
                case 'phone_number_2': oldPhoneNumber[1] = String(value); break;
                case 'phone_number_3': oldPhoneNumber[2] = String(value); break;
                default: break;
            }
            setValues({
                ...values,
                'phone_number': oldPhoneNumber
            });
    
            setErrorValues({
                ...errorValues,
                [name]: hasAnyError.isErrorEmpty
            })
            setErrorLength({
                ...errorLength,
                [name]: hasAnyError.isErrorLength
            })
            setErrorRegex({
                ...errorRegex,
                [name]: hasAnyError.isErrorRegex
            })
    
            if(hasAnyError.isErrorRegex){
                setTempValues({
                    ...tempValues,
                    [name]: value
                })
            } else {
                setTempValues({
                    ...tempValues,
                    [name]: null
                })
            }
        } else {
            setErrorValues({
                ...errorValues,
                [name]: hasAnyError.isErrorEmpty
            })
            setErrorLength({
                ...errorLength,
                [name]: hasAnyError.isErrorLength
            })
            setErrorRegex({
                ...errorRegex,
                [name]: hasAnyError.isErrorRegex
            })

            if(hasAnyError.isErrorRegex){
                setTempValues({
                    ...tempValues,
                    // [name]: value
                    [name]: hasAnyError.nonMatchingChars
                })
            } else {
                setTempValues({
                    ...tempValues,
                    [name]: ""
                })
            }

            setValues({
                ...values,
                [name]: value
            })
        }
    }

    const handleCheckZipCode = () => {
        setLoadingZipcode(true);
        fetch('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + values.zipcode, { method: 'GET' })
            .then((response) => response.json())
            .then((response: any) => {
            if(response && response.results){
                if(response.results.length === 1){
                    const pref_id = response.results[0].prefcode
                    setValues({
                        ...values,
                        prefecture_id: pref_id,
                        address1: response.results[0].address2 + response.results[0].address3
                    });
                    setLoadingZipcode(false);
                    // setError('');
                    setErrorValues({
                        ...errorValues,
                        prefecture_id: false,
                        address1: false
                    })
                    setErrorLength({
                        ...errorLength,
                        prefecture_id: false,
                        address1: false
                    })
                    setErrorRegex({
                        ...errorRegex,
                        prefecture_id: false,
                        address1: false
                    })
                    setError("")
                    setErrorNotFound(false);
                } else {
                    //more than 1
                    setCityChoices(response.results);
                    setLoadingZipcode(false);
                    // setError('');
                    setErrorValues({
                        ...errorValues,
                        prefecture_id: false,
                        address1: false
                    })
                    setErrorLength({
                        ...errorLength,
                        prefecture_id: false,
                        address1: false
                    })
                    setErrorRegex({
                        ...errorRegex,
                        prefecture_id: false,
                        address1: false
                    })
                    setError("")
                    setErrorNotFound(false);
                }
            } else {
                // setError('見つかりません') // Not Found
                setErrorNotFound(true);
                setLoadingZipcode(false);
                setValues({
                    ...values,
                    address1: ''
                });
            }
        });
    }

    const handleCheckPrivacy = (e: any) => {
        setPrivacyChecked(e.target.checked)
    }

    function chooseCity (e: any) {
        let index = e.target.id.split('-')[1];
        if(cityChoices){
            let choice = cityChoices[index];
            if(choice && (typeof choice === 'object')){
                setValues({
                    ...values,
                    prefecture_id: parseInt(choice?.prefcode),
                    address1: choice?.address2 + ' ' + choice?.address3
                });
                setLoadingZipcode(false);
            }
        }
        setCityChoices(null);
        setErrorValues(false)
    }


    function submit(){
        goToNextPage();
    }

    let hasError = phoneNumberTotalError;

    if(!hasError){
        Object.keys(errorValues).map((errName) => {
            if(errorValues[errName]){
                hasError = true;
            }
            if(errorLength[errName]){
                hasError = true;
            }
            if(errorRegex[errName]){
                hasError = true;
            }
        })
    }

    if(!hasError && (!values.name || !values.zipcode || !values.prefecture_id || !values.address1 || !values.address2 || !values.phone_number[0] || !values.phone_number[1] || !values.phone_number[2] || !values.email)){
        hasError = true;
    }

    // console.log(errorValues, errorLength, errorRegex);

    return (
        <div>
            <Title isMobile={isMobile} addMargin={true}>
                ご優待品の送付先をご入力ください。
            </Title>
            {cityChoices ?
                <CityChoices 
                    cityChoices={cityChoices} 
                    chooseCity={chooseCity} 
                />
            : 
                null
            }
            {errorMessage ?
                <div style={{marginBottom: '20px', width: '100% !important'}}>
                    <AlertError 
                        isMobile={isMobile}
                        errorMessage={errorMessage}
                    />
                </div>
            :
                null
            }
            {errorNotFound ?
                <div>
                    <ErrorPopup 
                        isMobile={isMobile}
                        open={(errorNotFound) ? true : false}
                        closeModal={() => setErrorNotFound(false)}
                    />
                </div>
            :
                null
            }
            <GridWhite container>
                {Object.keys(values).map((k, i) => {
                    if(k === "phone_number"){
                        return (
                            <InputRow 
                                key={'inputidx'+i}
                                isMobile={isMobile}
                                name={k}
                                value={values[k]}
                                tempValue={(tempValues["phone_number_1"] || "") + (tempValues["phone_number_2"] || "") + (tempValues["phone_number_3"] || "")}
                                handleChange={handleChange}
                                handleCheckZipCode={handleCheckZipCode}
                                prefectures={prefectures}
                                loadingZipcode={loadingZipcode}
                                isError={errorValues["phone_number_1"] || errorValues["phone_number_2"] || errorValues["phone_number_3"]}
                                isErrorLength={errorLength["phone_number_1"] || errorLength["phone_number_2"] || errorLength["phone_number_3"] || phoneNumberTotalError}
                                isErrorRegex={errorRegex["phone_number_1"] || errorRegex["phone_number_2"] || errorRegex["phone_number_3"]}
                                handleBlur={handleBlur}
                                // handleBlurPhoneNumber={handleBlurPhoneNumber}
                            />
                        )
                    }
                    return (
                        <InputRow 
                            key={'inputidx'+i}
                            isMobile={isMobile}
                            name={k}
                            value={values[k]}
                            tempValue={tempValues[k]}
                            handleChange={handleChange}
                            handleCheckZipCode={handleCheckZipCode}
                            prefectures={prefectures}
                            loadingZipcode={loadingZipcode}
                            isError={errorValues[k]}
                            isErrorLength={errorLength[k]}
                            isErrorRegex={errorRegex[k]}
                            handleBlur={handleBlur}
                            // handleBlurPhoneNumber={handleBlurPhoneNumber}
                        />
                    )
                })}
                <Grid item xs={12}>
                    {isMobile && <Spacer />}
                </Grid>
            </GridWhite>

            <Spacer />
            
            <Title isMobile={isMobile} addMargin={true}>
                株主様の個人情報の取扱いについて
            </Title>

            <GridWhite container justifyContent="center">
                <Grid item>
                    <Spacer />
                    <PrivacyPolicyLink target="_blank" href="https://www.delica.co.jp/privacy/" isMobile={isMobile}>個人情報保護方針<OpenInNewIcon /></PrivacyPolicyLink>
                </Grid>
                <Grid item xs={isMobile ? 12 : 11}>
                    <SubTitle isMobile={isMobile}>ご提供いただいた個人情報は、ご優待品発送の目的のみに使用し、他の目的には利用いたしません。上記の個人情報保護方針に同意いただいた上でお進みください。</SubTitle>
                </Grid>
                <Grid container item justifyContent="space-around">
                    <Grid item>
                        {/* <Title isMobile={isMobile}><Checkbox onChange={handleCheckPrivacy} checked={privacyChecked} disabled={hasError === true}  style={{fontSize: isMobile ? '14px' : '22px', alignItems: "center"}}/>個人情報保護方針に同意する</Title>  */}
                        <FormGroup onChange={handleCheckPrivacy} style={{marginLeft: isMobile ? '10px' : '20px'}}>
                            <FormControlLabel 
                                control={<Checkbox 
                                            checked={privacyChecked} 
                                            style={{fontSize: isMobile ? '14px' : '22px', alignItems: "center"}}/>
                                        } 
                                label="個人情報保護方針に同意する" 
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                      color: 'black',fontWeight: 'bold', fontSize: isMobile ? 16 : 24, margin: '20px 0px', padding: '0px',
                                    },
                                }}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Spacer />
                    </Grid>
                </Grid>
            </GridWhite>

            <Spacer />

            <Grid container>
                <Grid container item xs={6}>
                    <GrayButton isMobile={isMobile} onClick={goToPrevPage}>戻る</GrayButton>
                </Grid>
                <Grid container item xs={6}>
                    <DarkGreenButton isMobile={isMobile} disabled={hasError ? true : !privacyChecked} onClick={submit}>次へ</DarkGreenButton>
                </Grid>
            </Grid>
        </div>
    )
}

const FormTitle = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontSize: isMobile ? 16 : 20,
    fontWeight: 600,
    marginTop: '10px'
}));

const Star = styled("span")(() => ({
    color: red
}));

const GridForm = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    padding: isMobile ? '-2px 10px -2px 20px' : '10px'
}));

const TextInput = styled('input')<{isMobile: boolean, smaller?: boolean}>(({isMobile, smaller}) => ({
    width: smaller ? '80%' : '100%',
    fontSize: isMobile ? 15 : 20,
    padding: isMobile ? '7px 0px 7px 7px' : '10px',
    height: isMobile ? 25 : 28,
    borderRadius: 5,
    border: '1px solid gray',
    marginRight: '10px'
}));

const TextInputNumber = styled('input')<{isMobile: boolean, smaller?: boolean}>(({isMobile, smaller}) => ({
    // Hiding the spinner buttons for webkit browsers
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        display: 'none',
    },
    // Hiding the spinner buttons for Firefox
    '-moz-appearance': 'textfield',
    width: smaller ? '80%' : '100%',
    fontSize: isMobile ? 15 : 20,
    padding: isMobile ? '7px 0px 7px 7px' : '10px',
    height: isMobile ? 25 : 28,
    borderRadius: 5,
    border: '1px solid gray',
    marginRight: '10px'
}));

const SelectGrid = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    paddingRight: isMobile ? '10px' : '10px',
}));

const SelectWrapper = styled('div')<{isMobile: boolean}>(({isMobile}) => ({
    width: isMobile ? '99%' : '100%',
    borderRadius: 5,
    border: '1px solid gray',
    padding: '3px 0px 3px 3px',
}));

const SelectInput = styled('select')<{isMobile: boolean}>(({isMobile}) => ({
    width: '90%',
    padding: isMobile ? '4px 0px 4px 4px' : '10px',
    height: isMobile ? 39 : 48,
    fontSize: isMobile ? 15 : 20,
    border: 'none',
}));

const OrangeButton = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: orange,
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: '100%',
    height: isMobile ? '40px' : '48px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    marginLeft: '-10px',
    marginRight: '20px'
}));

const GrayButton = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: gray,
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: '100%',
    height: isMobile ? '48px' : '80px',
    borderRadius: '10px',
    border: 'none',
    marginTop: '15px',
    marginBottom: '20px',
    marginRight: '20px',
    cursor: 'pointer'
}));

const PrivacyPolicyLink = styled("a")<{isMobile: boolean}>(({isMobile}) => ({
    color: blue,
    fontSize: isMobile ? 24 : 32,
    textAlign: 'center',
    textDecoration: 'none'
}));