import React, { useEffect, useState, } from "react";
import { Button,  Grid, TextField, CircularProgress, Card, CardContent } from '@mui/material';
import AlertError from "../components/AlertError";
// import moment from 'moment';
import moment from 'moment-timezone';
// import AlertSuccess from "../components/AlertSuccess";

interface LoginProps {
    isMobile: boolean,
    fetchServer: (url: any, method: any, body: any, headers: any, forwardRes: any) => void,
    setToken: (token: string) => void,
    scheduleStartDate: any,
}

function Login({ isMobile, fetchServer, setToken, scheduleStartDate } : LoginProps){

    const [loading, setLoading] = useState(false);
    const [shareholderID, setShareholderID] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setError] = useState("");
    const [errorShareholder, setErrorShareholder] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const [isNotScheduledYet, setIsNotScheduledYet] = useState(false);

    useEffect(()=>{
        let now = moment();
        if(moment(now).isBefore(scheduleStartDate)){
            setIsNotScheduledYet(true)
        }
    }, [scheduleStartDate]);

    function handleFieldText (e:any) {
        const name = e.target.name;
        const value = e.target.value;
        if(name === 'shareholder_id'){
            setShareholderID(value);
            setErrorShareholder('');
        }
        if(name === 'password'){
            setPassword(value);
            setErrorPassword('');
        }
    }

    const handleBlur = (e:any) => {
        const name = e.target.name;
        let value = e.target.value;
        if(name === 'shareholder_id'){
            value = value.substring(0,10);
            value = value.replace(/[^a-zA-Z0-9-]/g, '');
            setShareholderID(value);
        }
        if(name === 'password'){
            value = value.substring(0,8);
            value = value.replace(/[^a-zA-Z0-9 -]/g, '');
            setPassword(value);
        }
    }

    function login () {
        setLoading(true);
        if(!shareholderID ){
            setErrorShareholder('株主番号が入力されてません。');
            setLoading(false);
        } else if(!password ){
            setErrorPassword('パスワードが入力されてません。');
            setLoading(false);
        } else{
            let headers = { 'Content-Type' : 'application/json' }
            let body = JSON.stringify({ 
                code: shareholderID,
                password: password,
            })
            fetchServer(
                process.env.REACT_APP_BASE_URL + '/v1/login', 
                'POST', 
                body, 
                headers,
                async (response: any) => {
                    response = await response.json();
                    if(response.data.token){ //Success login
                        setToken(response.data.token);
                    } else if (response.data === 'unauthenticated'){ //Wrong password or user ID
                        setError('IDまたはパスワードが一致しません。');
                        // setError(shareholderID + 'が入力されてません。');
                        setLoading(false);
                        setErrorShareholder('');
                        setErrorPassword('');
                    } else { // If input 5 time wrong password
                        setError('ログイン情報を続けて間違えたため、一時的にロックしています。\n５分以上時間を空けてから再度お試しください。');
                        setLoading(false);
                        setErrorShareholder('');
                        setErrorPassword('');
                    }
                }
            )
        }
    }

    let startdate = moment.tz(scheduleStartDate,'Asia/Tokyo');

    return(
            <div>
                <div id="app">
                    <div className="container-fluid" style={{paddingTop: '50px'}}>
                        <p style={{textAlign: 'center', marginBottom: '10px', fontSize: isMobile ? '18px' : '32px', color: '#46965A'}}>デリカフーズホールディングス株式会社<br/>株主優待お申込みサイト</p>
                        {isNotScheduledYet ?
                            <div>
                                <p style={{textAlign: 'center', fontSize: isMobile ? '14px' : '20px', color: '#000000',}}>受付開始まで今しばらくお待ちください。</p>
                                <p style={{textAlign: 'center', fontSize: isMobile ? '16px' : '24px', color: '#000000', fontWeight: 'bold'}}>
                                    開始日時：{startdate.format('YYYY')}年{startdate.format('MM')}月{startdate.format('DD')}日 {startdate.format('HH:mm')} ～
                                </p>
                            </div>
                        :
                            null
                        }

                        {errorMessage ?
                            <div style={{marginLeft: isMobile ? 'calc(50vw - 160px)' : 'calc(50vw - 250px)', marginBottom: isMobile ? '' : '20px'}}>
                                <AlertError 
                                    isMobile={isMobile}
                                    errorMessage={errorMessage}
                                />
                            </div>
                        :
                            null
                        }

                        {isMobile ?
                            <Grid container alignItems='center' style={{padding: '20px'}}>
                                {/* LOGIN FORM */}
                                <Grid container item xs={12}>
                                    <Card style={{backgroundColor: '#F9FFF9', width: '100%'}}>
                                        <CardContent>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    <p style={{marginTop: '8px', fontSize: '16px', fontWeight: 'bold', margin: 0}}>ID（株主番号）</p>
                                                    <TextField variant="outlined" size="small" fullWidth value={shareholderID} name="shareholder_id" onChange={handleFieldText} onBlur={handleBlur} placeholder='株主番号をご入力ください' style={{backgroundColor: 'white'}}/>
                                                </Grid>
                                                {errorShareholder ? 
                                                    <Grid item xs={12}>
                                                        <p style={{fontSize: '10px', color:'#E80000', margin: 0}}>{errorShareholder}</p>
                                                    </Grid>
                                                :
                                                    null
                                                }
                                            </Grid>

                                            <Grid container item xs={12} style={{marginTop: '10px'}}>
                                                <Grid item xs={12}>
                                                    <p style={{marginTop: '8px', fontSize: '16px', fontWeight: 'bold', margin: 0}}>パスワード</p>
                                                    <TextField type="password" variant="outlined" size="small" fullWidth value={password} name="password" onChange={handleFieldText} onBlur={handleBlur} placeholder='パスワードをご入力ください' style={{backgroundColor: 'white'}}/>
                                                </Grid>
                                                {errorPassword ? 
                                                    <Grid item xs={12}>
                                                        <p style={{fontSize: '10px', color:'#E80000', margin: 0}}>{errorPassword}</p>
                                                    </Grid>
                                                :
                                                    null
                                                }
                                            </Grid>

                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    {loading ? 
                                                        <Button disabled={loading ? true : false} style={{marginBottom: '10px', color: 'white', backgroundColor: '#1876D0', width: '100%', height:'60px', borderRadius: '10px', marginTop: '10px' }}>
                                                            <CircularProgress size="25px" style={{color: 'white'}}/>
                                                        </Button>
                                                    :
                                                        <Button onClick={login} disabled={isNotScheduledYet ? true : false} style={{fontWeight: 'bold', marginBottom: '10px', color:'white', backgroundColor: isNotScheduledYet ? 'gray' : '#1876D0', width: '100%', height:'60px', borderRadius: '10px', fontSize:'24px', marginTop: '20px', border: '1px solid #999999'}}>ログイン</Button>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    <Grid container item xs={12} style={{paddingBottom: '10px'}}>
                                        <Grid item xs={12}>
                                            <p style={{fontSize: '12px'}}>※ 株主番号とパスワードは「デリカフーズホールディングス株式会社 株主様 ご優待のご案内及びID・パスワードのご通知」ハガキをご確認ください</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        :
                            <Grid container alignItems='flex-start' spacing={3} justifyContent="center">
                                {/* left side */}
                                <Grid container item style={{width: '500px', height: '350px'}}>
                                    <Card style={{backgroundColor: '#F9FFF9'}}>
                                        <CardContent style={{padding: '30px'}}>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    <p style={{fontSize: '16px', fontWeight: 'bold', margin: 0}}>ID（株主番号）</p>
                                                    <TextField variant="outlined" size="small" fullWidth value={shareholderID} name="shareholder_id" onChange={handleFieldText} onBlur={handleBlur} placeholder='株主番号をご入力ください' style={{backgroundColor: 'white'}}/>
                                                </Grid>
                                                {errorShareholder ? 
                                                    <Grid item xs={12}>
                                                        <p style={{fontSize: '12px', color:'#E80000', margin: 0}}>{errorShareholder}</p>
                                                    </Grid>
                                                :
                                                    null
                                                }
                                            </Grid>

                                            <Grid container item xs={12} style={{marginTop: '10px'}}>
                                                <Grid item xs={12}>
                                                    <p style={{fontSize: '16px', fontWeight: 'bold', margin: 0}}>パスワード</p>
                                                    <TextField type="password" variant="outlined" size="small" fullWidth value={password} name="password" onChange={handleFieldText} onBlur={handleBlur} placeholder='パスワードをご入力ください' style={{backgroundColor: 'white'}}/>
                                                </Grid>
                                                {errorPassword ? 
                                                    <Grid item xs={12}>
                                                        <p style={{fontSize: '12px', color:'#E80000', margin: 0}}>{errorPassword}</p>
                                                    </Grid>
                                                :
                                                    null
                                                }
                                            </Grid>

                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    {loading ? 
                                                        <Button disabled={loading ? true : false} style={{marginBottom: '10px', color: 'white', backgroundColor: '#1876D0', width: '416px', height:'50px', marginTop: '20px', borderRadius: '10px', }}>
                                                            <CircularProgress size="25px" style={{color: 'white'}}/>
                                                        </Button>
                                                    :
                                                        <Button onClick={login} disabled={isNotScheduledYet ? true : false} style={{fontWeight: 'bold', marginBottom: '10px', color:'white', backgroundColor: isNotScheduledYet ? 'gray' : '#1876D0', width: '416px', height:'50px', borderRadius: '10px', fontSize:'24px', marginTop: '20px', border: '1px solid #999999'}}>ログイン</Button>
                                                        // <Button onClick={login} style={{fontWeight: 'bold', marginBottom: '10px', color:'white', backgroundColor: '#1876D0', width: '435px', height:'60px', borderRadius: '10px', fontSize:'24px', marginTop: '20px', border: '1px solid #999999'}}>ログイン</Button>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    <Grid container item xs={12} style={{paddingBottom: '50px'}}>
                                        <Grid item xs={12}>
                                            <p style={{fontSize: '12px'}}>※ 株主番号とパスワードは「デリカフーズホールディングス株式会社 株主様 ご優待のご案内及びID・パスワードのご通知」ハガキをご確認ください</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </div>
                </div>
            </div>
    );
}

export default Login;