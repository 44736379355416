import React, { useEffect, } from "react";

interface Props {
    history: any,
}

export default function Redirector ({ history }: Props) {
    useEffect(()=>{
        history.push('/404')
    });

    return null
}