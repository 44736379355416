import React from 'react';
import { styled } from 'styled-components';

interface Props {
    isMobile: boolean,
}

export default function StaticView ({ isMobile }: Props) {
    return (
        <div>
            <Border isMobile={isMobile}>
                <Title isMobile={isMobile}>デリカフーズホールディングス株式会社<br/>株主優待お申込みサイト</Title>
                
                <Content isMobile={isMobile}>2024年度の株主優待のお申込みは11月頃に開始予定です。<br/>受付開始まで今しばらくお待ちください。<br/>株主優待に関するお問い合わせは弊社担当窓口までお願い致します。</Content>

                <Line />
                
                <Content3 isMobile={isMobile}>お問合せ先</Content3>
                <Content2 isMobile={isMobile}>
                    デリカフーズホールディングス株式会社 株主優待事務局
                </Content2>
                <Content2 isMobile={isMobile}>
                    TEL: 03-3858-1037 （10:00 ～ 17:00 土・日・祝を除く）
                </Content2>
            </Border>
        </div>
    )
}

const Border = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    border: '1px solid #46965A',
    width: isMobile ? '350px' : '750px',
    height: 'auto',
    padding: '20px',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 195px);' : 'calc(50% - 395px);',
    marginTop: '50px'
}));

const Title = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#46965A',
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 32,
    textAlign: 'center'
}));

const Content = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#000000',
    fontWeight: 'regular',
    fontSize: isMobile ? 14 : 20,
}));

const Line = styled("div")(() => ({
    borderTop: '1px solid #46965A',
    marginTop:'-10px',
    marginBottom: '10px'
}));

const Content2 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontWeight: 'regular',
    fontSize: isMobile ? 12 : 16,
    margin: 0
}));

const Content3 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#000000',
    fontWeight: 'bold',
    fontSize: isMobile ? 12 : 16,
    margin: 0
}));