import React, { useState } from 'react';
import { styled } from 'styled-components';
import { Grid, Radio, Box, Table, TableBody, TableCell, Typography, RadioGroup, FormControlLabel } from '@mui/material';
import { darkGreen, orange } from '../../StyleVariables';
import { Title, SubTitle, GridWhite, Spacer, DarkGreenButton, DetailPopUp, Image, OutsideImage, OutsideImage2 } from '../../components/Common';
import { AspectRatio } from '@mui/joy';

interface GiftProps {
    isMobile: boolean,
    id: number,
    name: string,
    link: string,
    chosen: number | null,
    setChosen: (id: number) => void,
    setOpenDetail: (id: number) => void
}

function Gift ( { isMobile, id, name, link, chosen, setChosen, setOpenDetail }: GiftProps ) {

    const choose = () => {
        setChosen(id)
    }

    const openDetail = () => {
        setOpenDetail(id)
    }

    return (
        <GiftContainer container item xs={isMobile ? 12 : 6} alignItems="center" isMobile={isMobile}>
            <Grid item xs={12}>
                {/* <Radio checked={chosen === id} onChange={choose}/> */}
                <RadioGroup onChange={choose}>
                    <FormControlLabel checked={chosen === id} value={name} control={<Radio />} label={name} style={{ maxHeight: isMobile ? 'auto' : '75px', minHeight: isMobile ? 'auto' : '48px', alignItems: 'center', fontSize: isMobile ? '16px' : '24px', color: "black", width:'103%',  letterSpacing: '0.1009em' }}/>
                </RadioGroup>
            </Grid>
            {/* <Grid container item xs={11} style={{paddingLeft: isMobile ? '10px' : '0px'}}>
                <Typography sx={{ height: 'auto', minHeight: isMobile ? '0px' : '110px', display:"flex", alignItems:"center", justifyContent:"center", fontSize: isMobile ? '16px' : '24px', color: "black"}}>
                    {name}
                </Typography>
            </Grid> */}
            <Grid item xs={12}>
                {/* <OutsideImage isMobile={isMobile}> */}
                <AspectRatio ratio={1} style={{width: '100%', border: '1px solid black'}}>
                    <Image src={link} alt="product" isMobile={isMobile}/>
                {/* </OutsideImage> */}
                </AspectRatio>
            </Grid>
            <Grid item xs={12}>
                <OrangeButton isMobile={isMobile} onClick={openDetail}>
                    詳細を見る
                </OrangeButton>
            </Grid>
        </GiftContainer>
    )
}

interface GiftDetailProps {
    isMobile: boolean,
    link: string,
    name: string,
    text: string,

}

function GiftDetail ({isMobile, link, name, text}: GiftDetailProps) {
    return (
        <>
            <GridCommon item xs={isMobile ? 12 : 6} isMobile={isMobile}>
                <AspectRatio ratio={1} style={{width: '100%', border: '1px solid black'}}>
                {/* <OutsideImage isMobile={isMobile}> */}
                    <Image src={link} alt="default_product" isMobile={isMobile}/>
                {/* </OutsideImage> */}
                </AspectRatio>
            </GridCommon>
            <GridCommon item xs={isMobile ? 12 : 6} isMobile={isMobile}>
                <Title isMobile={isMobile}>
                    {name}
                </Title>
                <SubTitle isMobile={isMobile}>
                    {text}
                </SubTitle>
            </GridCommon>
        </>
    )
}

interface Props {
    isMobile: boolean,
    goToNextPage: () => void,
    user: any,
    chosen: any,
    setChosen: (chosen: any) => void,
}

export default function Step1 ({ isMobile, goToNextPage, user, chosen, setChosen }: Props) {

    const [ openDetail, setOpenDetail ] = useState<number|null>(null);
    if(!user)return null;

    const special = user.special;
    const detail = user.rank.items.find((item: any) => item.id === openDetail);
    const rank = user.rank;

    return (
        <div>
            {openDetail &&
                <DetailPopUp
                    isOpen={(openDetail !== null) ? true : false}
                    close={() => setOpenDetail(null)}
                    isMobile={isMobile}
                >
                    <GiftDetail
                        isMobile={isMobile}
                        link={detail.image}
                        name={detail.name}
                        text={detail.detail}
                    />
                </DetailPopUp>
            }

            <Title isMobile={isMobile} addMargin={true}>
                株主情報をご確認の上、ご優待品をお選びください。
            </Title>

            <TitleGrid container>
                <GridGreen isMobile={isMobile} item xs={isMobile ? 12 : 2} alignItems="center">{isMobile ? '株主情報' : <p>株主情報</p>}</GridGreen>
                <GridWhite item xs={isMobile ? 12 : 10}>
                    {special ?
                        <TextGreen isMobile={isMobile}>保有株式数：{rank.name}</TextGreen>
                    :
                        <TextGreen2 isMobile={isMobile}>保有株式数：{rank.name}</TextGreen2>
                    }
                    {special ? <TextGreen isMobile={isMobile}>長期優遇優待：{special.name}</TextGreen> : null}
                </GridWhite>
            </TitleGrid>

            { special && <>
                <Spacer />
                <Grid container>
                    <GridGreen isMobile={isMobile} item xs={12}>株主様は長期優遇優待の対象です</GridGreen>
                    <GridWhite container item xs={12} alignItems="flex-start">
                        {/* <GiftDetail
                            isMobile={isMobile}
                            link={special.item.image}
                            name={special.item.name}
                            text={special.item.detail}
                            special={special}
                        /> */}
                        <GridCommon item xs={isMobile ? 12 : 4} isMobile={isMobile}>
                            {/* <OutsideImage2 isMobile={isMobile}> */}
                            <AspectRatio ratio={1}>
                                <Image src={special.item.image} alt="default_product" isMobile={isMobile} style={{width:'100%', objectFit: 'contain', objectPosition: 'center center', border: '1px solid black'}}/>
                            {/* </OutsideImage2> */}
                            </AspectRatio>
                        </GridCommon>
                        <GridCommon item xs={isMobile ? 12 : 8} isMobile={isMobile}>
                            <Title isMobile={isMobile}>
                                {special.item.name}
                            </Title>
                            <SubTitle isMobile={isMobile}>
                                {special.item.detail}
                            </SubTitle>
                        </GridCommon>
                    </GridWhite>
                </Grid>
                <PlusIcon isMobile={isMobile}>+</PlusIcon>
            </>
            }

            <Spacer />
            
            <Grid container>
                <GridGreen isMobile={isMobile} item xs={12}>以下から一点をご選択ください</GridGreen>
                <GridWhite container item xs={12}>
                    {user.rank.items.map((item: any) => {
                        return (
                             <Gift
                                key={'gift-'+item.id}
                                isMobile={isMobile}
                                id={item.id}
                                name={item.name}
                                link={item.image}
                                chosen={chosen}
                                setChosen={setChosen}
                                setOpenDetail={setOpenDetail}
                                
                            />
                        )
                    })}
                </GridWhite>
            </Grid>

            <Spacer />

            <DarkGreenButton isMobile={isMobile} disabled={(chosen === null)} onClick={goToNextPage}>
                次へ
            </DarkGreenButton>
        </div>
    )
}

const PlusIcon = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: darkGreen,
    fontSize: isMobile ? 64 : 100,
    margin: '-10px',
    padding: '0px',
    textAlign: 'center'
}));

const TitleGrid = styled(Grid)(() => ({
    padding: '0px',
    margin: '0px',
}));

const TextGreen = styled("p")<{textColor? : string, isMobile: boolean}>(({textColor = '', isMobile}) => ({
    padding: '0px', 
    margin: '0px',
    fontSize: isMobile ? 16 : 24,
    color: textColor ? textColor : darkGreen,
}));

const TextGreen2 = styled("p")<{textColor? : string, isMobile: boolean}>(({textColor = '', isMobile}) => ({
    padding: '0px', 
    margin: '0px',
    fontSize: isMobile ? 16 : 24,
    color: textColor ? textColor : darkGreen,
    paddingTop: isMobile ? '' : '20px',
    paddingBottom: isMobile ? '' : '20px',
}));

const Smaller = styled("span")<{isMobile: boolean}>(({isMobile}) => ({
    fontWeight: 'bold',
    fontSize: isMobile ? 14 : 16,
    margin: '0px',
    padding: '0px'
}));

const GridGreen = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: darkGreen,
    fontSize: isMobile ? 16 : 24,
    padding: '0px',
    margin: '0px',
    textAlign: 'center',
    fontWeight: 'bold',
    height: 'auto'
}));

const GiftContainer = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    padding: '10px',
    fontSize: isMobile ? 16 : 24,
    alignContent: 'center',
}));

const OrangeButton = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: orange,
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: '100%',
    height: isMobile ? '34px' : '48px',
    borderRadius: '10px',
    border: 'none',
    marginTop: '15px',
    // marginBottom: isMobile ? '' : '20px',
    cursor: 'pointer'
}));

const GridCommon = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    padding: isMobile ? '' : '10px',
    margin: '0px',
    height: 'auto',
    paddingBottom:  isMobile ? '' : '20px'
}));