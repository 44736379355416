import React from 'react';
import { AppBar } from '@mui/material';

const Header = () => {
    return (
        <AppBar style={{backgroundColor:'white', position: 'fixed', top: '0px', left: '0px', right: '0px'}}>
            <div style={{width:'100%', backgroundColor:'#46965A', height: '5px'}}/>
            <img src="/logo.png" alt="" style={{width: '253px', height: 'auto', padding: 0, margin:'2px', cursor: 'pointer', marginLeft: '10px'}}/>
        </AppBar>
    );
}

export default Header;