import React, { useState } from 'react';
import { styled } from 'styled-components';
import { Grid, CircularProgress } from '@mui/material';
import { darkGreen, gray } from '../../StyleVariables';
import { Title, GridWhite, Spacer, formatZipcode } from '../../components/Common';
import { AspectRatio } from '@mui/joy';

interface Props {
    isMobile: boolean,
    goToNextPage: () => void,
    goToPrevPage: () => void,
    user: any,
    chosen: any,
    values: any,
    prefectures: any,
    fetchServer: (url: any, method: any, body: any, headers: any, forwardRes: any) => void,
}

interface GiftProps {
    isMobile: boolean,
    name: string,
    link: string,
}

function Gift ( { isMobile, name, link, }: GiftProps ) {

    return (
        <GiftContainer isMobile={isMobile} container item xs={isMobile ? 12 : 6} alignItems="center" style={{paddingBottom: '30px'}} >
            <Grid container item style={{height: isMobile ? 'auto' : '75px', alignItems: 'center'}}>
                {name}
            </Grid>
            <Grid item xs={12}>
                <AspectRatio ratio={1} style={{width: '100%', border: '1px solid black'}}>
                    <Image isMobile={isMobile} src={link} alt="product" />
                </AspectRatio>
            </Grid>
        </GiftContainer>
    )
}

export default function Step3 ({ isMobile, goToNextPage, goToPrevPage, user, chosen, values, prefectures, fetchServer }: Props) {
    const [loadingSubmit, setLoadingSubmiut] = useState(false);
    
    if(!user)return null;
    
    const special = user.special;
    const item = user.rank.items.find((item: any) => item.id === chosen);
    const prefecture = prefectures.find((pref: any) => String(pref.id) === String(values.prefecture_id));

    function submitForm () {
        setLoadingSubmiut(true);
        let headers = { 'Content-Type' : 'application/json' }
        let body = JSON.stringify({ 
            item_id: chosen,
            name: values.name,
            zipcode: values.zipcode,
            prefecture_id: prefecture.id,
            address1: values.address1,
            address2: values.address2,
            address3: values.address3,
            phone_number1: values.phone_number[0],
            phone_number2: values.phone_number[1],
            phone_number3: values.phone_number[2],
            email: values.email
        })
        fetchServer(
            process.env.REACT_APP_BASE_URL + '/v1/apply', 
            'POST', 
            body, 
            headers,
            async (response: any) => {
                response = await response.json();
                if(response.data === "submitted"){
                    //Success submit
                    goToNextPage();
                    setLoadingSubmiut(false);
                } else{
                    // Failed submit form
                    alert('入力を確認してください。');
                    setLoadingSubmiut(false);
                }
            }
        )
    }

    return (
        <div>
            <Spacer/>
            {/* <Outside isMobile={isMobile}> */}
                <Title isMobile={isMobile}>
                    入力情報をご確認の上、申し込みを完了してください。
                </Title>
                
                {/* LIST BENEFITS */}
                {/* <Border isMobile={isMobile}> */}
                    {special ?
                        <Grid container>
                            <GridGreen isMobile={isMobile} item xs={12}>ご優待品</GridGreen>
                            <GridWhite container item xs={12}>
                                <Gift
                                    isMobile={isMobile}
                                    name={special.item.name}
                                    link={special.item.image}
                                />

                                {isMobile ? 
                                    <Liner isMobile={isMobile} />
                                :
                                    null
                                }
                                
                                <Gift
                                    isMobile={isMobile}
                                    name={item.name}
                                    link={item.image}
                                />
                            </GridWhite>
                        </Grid>
                    :
                    <Grid container>
                        {/* <Box isMobile={isMobile}>
                            <Title2 isMobile={isMobile}>ご優待品</Title2>
                        </Box> */}
                        <GridGreen isMobile={isMobile} item xs={12}>ご優待品</GridGreen>
                        <GridWhite container item xs={12}>
                            <Inside isMobile={isMobile}>
                                <Content isMobile={isMobile}>
                                    {item.name}
                                </Content>
                                <ContentImg 
                                    src = {item.image}
                                    isMobile={isMobile}
                                />
                            </Inside>
                        </GridWhite>
                    </Grid>}
                {/* </Border> */}

                <Spacer />

                {/* DETAIL ADDRESS */}
                <Grid container>
                {/* <Border isMobile={isMobile}> */}
                    <GridGreen isMobile={isMobile} item xs={12}>送付先</GridGreen>
                    <GridWhite container>
                        {/* <Box isMobile={isMobile}>
                            <Title2 isMobile={isMobile}>送付先</Title2>
                        </Box> */}
                        <Inside2 isMobile={isMobile}>
                            <Grid container>
                                <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>お名前</GridTitle>
                                <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{values.name}</GridContent>
                            </Grid>

                            <Liner isMobile={isMobile} />

                            <Grid container>
                                <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>郵便番号</GridTitle>
                                <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{formatZipcode(values.zipcode)}</GridContent>
                            </Grid>

                            <Liner isMobile={isMobile} />

                            <Grid container>
                                <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>都道府県</GridTitle>
                                <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{prefecture.name}</GridContent>
                            </Grid>

                            <Liner isMobile={isMobile} />

                            <Grid container>
                                <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>市区町村</GridTitle>
                                <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{values.address1}</GridContent>
                            </Grid>

                            <Liner isMobile={isMobile} />

                            <Grid container>
                                <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>丁番地</GridTitle>
                                <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{values.address2}</GridContent>
                            </Grid>

                            <Liner isMobile={isMobile} />

                            <Grid container>
                                <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>マンション・ビル名</GridTitle>
                                <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{values.address3 === '' ? '-' : values.address3}</GridContent>
                            </Grid>

                            <Liner isMobile={isMobile} />

                            <Grid container>
                                <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>電話番号</GridTitle>
                                <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{values.phone_number[0]}-{values.phone_number[1]}-{values.phone_number[2]}</GridContent>
                            </Grid>
                            
                            <Liner isMobile={isMobile} />

                            <Grid container>
                                <GridTitle isMobile={isMobile} item xs={isMobile ? 12 : 4}>メールアドレス</GridTitle>
                                <GridContent isMobile={isMobile} item xs={isMobile ? 12 : 8}>{values.email}</GridContent>
                            </Grid>
                        </Inside2>
                    </GridWhite>
                    {/* </Border> */}
                </Grid>

                <Spacer />

                <Info isMobile={isMobile}>※受付完了後のメールを受け取る際は、送信元ドメイン：[@delicafoodsholdings-yutai.jp]からのメールを受信できるようにドメインの受信をご設定ください。</Info>

                <Grid container>
                    <Grid container item xs={6}>
                        <GrayButton isMobile={isMobile} onClick={goToPrevPage} disabled={loadingSubmit ? true : false}>戻る</GrayButton>
                    </Grid>
                    <Grid container item xs={6}>
                        {loadingSubmit ? 
                            <DarkGreenButton isMobile={isMobile} disabled={loadingSubmit ? true : false}>
                                <CircularProgress size="25px" style={{color: 'white', marginLeft: '20px'}}/>
                            </DarkGreenButton>
                        :
                            <DarkGreenButton isMobile={isMobile} onClick={submitForm}>申し込む</DarkGreenButton>
                        }
                    </Grid>
                </Grid>
            {/* </Outside> */}
        </div>
    )
}

const Outside = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    width: isMobile ? '350px' : '850px',
    height: 'auto',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 195px);' : 'calc(50% - 550px);',
    marginTop: isMobile ? '10px' : '50px'
}));

// const Title = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
//     color: 'black',
//     fontWeight: 'bold',
//     fontSize: isMobile ? 20 : 24,
//     textAlign: 'left'
// }));

const Border = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    border: '1px solid #46965A',
    width: isMobile ? '350px' : '850px',
    height: 'auto',
    margin: '0px',
    marginBottom: '30px',
    alignItems: "center",
    justifyContent: "flex-start"
}));

const Box = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    backgroundColor: '#46965A',
    width: isMobile ? '350px' : '850px',
    height: '50px',
}));

const GridGreen = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: darkGreen,
    fontSize: isMobile ? 16 : 24,
    padding: '0px',
    margin: '0px',
    textAlign: 'center',
    fontWeight: 'bold',
    height: 'auto'
}));

const Title2 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 24,
    textAlign: 'center',
    paddingTop: '5px'
}));

const Inside = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    width: isMobile ? '293px' : '500px',
    height: 'auto',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 146px);' : 'calc(50% - 250px);',
    marginTop: '10px',
    paddingBottom: '20px'
}));

const Content = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#000000',
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 24,
}));

const ContentImg = styled("img")<{isMobile: boolean}>(({isMobile}) => ({
    width: isMobile ? '300px' : '500px',
    height: isMobile ? '300px' : '500px'
}));

// const GridWhite = styled(Grid)(() => ({
//     padding: '5px 10px 0px 10px',
//     margin: '0px',
//     height: 'auto'
// }));

const GiftContainer = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    padding: '10px',
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 24,
}));

const Image = styled("img")<{isMobile: boolean}>(({isMobile}) => ({
    width: '100%',
    objectFit: 'contain',
    objectPosition: "center center"
}));

const Liner = styled('hr')<{isMobile: boolean}>(({isMobile}) => ({
    border: '1px solid #46965A',
    width: '100%',
}));

// DETAIL ADDRESS
const Inside2 = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    width: isMobile ? '318px' : '850px',
    height: 'auto',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 159px);' : 'calc(50% - 408px);',
    marginTop: '10px',
    paddingBottom: '10px'
}));

const GridTitle = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontSize: isMobile ? 12 : 24,
    fontWeight: isMobile ? 'regular' : 'bold',
    padding: '5px 10px'
}));

const GridContent = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontSize: isMobile ? 16 : 24,
    fontWeight: 'normal',
    padding: '5px 10px'
}));

const Info = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#E80000',
    fontWeight: 'regular',
    fontSize: isMobile ? 10 : 14,
    marginTop: '-10px'
}));

const GrayButton = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: gray,
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: '100%',
    height: isMobile ? '48px' : '80px',
    borderRadius: '10px',
    border: 'none',
    marginTop: '15px',
    marginBottom: '20px',
    marginRight: '20px',
    cursor: 'pointer'
}));

const DarkGreenButton = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: darkGreen,
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: '100%',
    height: isMobile ? '48px' : '80px',
    borderRadius: '10px',
    border: 'none',
    marginTop: '15px',
    marginBottom: '20px',
    marginLeft: '20px',
    cursor: 'pointer'
}));