import React from 'react';
import { styled } from 'styled-components';

interface Props {
    isMobile: boolean,
    goToLoginPage: () => void,
}

export default function NotFound ({ isMobile, goToLoginPage }: Props) {
    return (
        <div>
            <Border isMobile={isMobile}>
                <Title isMobile={isMobile}>404</Title>
                <Title2 isMobile={isMobile}>Not Found</Title2>
                
                <Content isMobile={isMobile}>アクセスしようとしたページは見つかりません。</Content>
            </Border>
            <ButtonReturn onClick={goToLoginPage} isMobile={isMobile}>TOPページに戻る</ButtonReturn>
        </div>
    )
}

const Border = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    border: '1px solid #46965A',
    width: isMobile ? '350px' : '550px',
    height: 'auto',
    padding: '20px',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 195px);' : 'calc(50% - 275px);',
    marginTop: '50px'
}));

const Title = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#46965A',
    fontWeight: 'bold',
    fontSize: 40,
    textAlign: 'center',
    marginBottom: '0px',
    margin: 0
}));

const Title2 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#46965A',
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'center',
    marginTop: '0px'
}));

const Content = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#000000',
    fontWeight: 'regular',
    fontSize: isMobile ? 12 : 16,
    textAlign: 'center',
    marginTop: '40px'
}));

const ButtonReturn = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: '#808080',
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: isMobile ? '206px' : '249px',
    height: isMobile ? '56px' : '64px',
    borderRadius: '10px',
    border: 'none',
    marginTop: isMobile ? '20px' : '30px',
    marginBottom: '20px',
    position: 'relative',
    marginLeft: isMobile ? 'calc(50% - 103px)' : 'calc(50% - 124px)',
    cursor: 'pointer',
}));