import React from 'react';
import { Grid } from '@mui/material';

interface FooterProps {
    isMobile: boolean
}

const Footer = ( { isMobile } : FooterProps ) => {
    return <>
      {isMobile?
        <div style={{width:'100%', bottom: 0, backgroundColor:'#46965A'}}>
            <Grid container alignItems='center' style={{padding: '10px'}}>
                <Grid container alignItems='center' justifyContent='center'>
                    <Grid container item xs={12}>
                          <Grid container item xs={12} spacing={3} alignItems='center' justifyContent='center'>
                              <Grid item>
                                  <a target="_blank" href="https://www.delica.co.jp/privacy/" rel="noreferrer" style={{color:'white', textAlign: 'center', textDecoration: 'underline'}}>個人情報保護方針</a>
                              </Grid>
                          </Grid>
                        <Grid item xs={12}>
                            <p style={{marginTop: '8px', color:'white', textAlign: 'center'}}>Copyright © DELICA FOODS HOLDINGS CO.,LTD. All Rights Reserved</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
      :
        <div className="container-fluid" style={{width:'100%', bottom: 0, marginTop: '40px', backgroundColor:'#46965A'}}>
          <Grid container alignItems='center' style={{padding: '10px'}}>
              <Grid container alignItems='center' justifyContent='center'>
                  <Grid container item xs={12}>
                      <Grid container item xs={12} spacing={3} alignItems='center' justifyContent='center'>
                          <Grid item>
                              <a target="_blank" href="https://www.delica.co.jp/privacy/" rel="noreferrer" style={{color:'white', textAlign: 'center', textDecoration: 'underline'}}>個人情報保護方針</a>
                          </Grid>
                      </Grid>
                      <Grid item xs={12}>
                          <p style={{marginTop: '8px', color:'white', textAlign: 'center'}}>Copyright © DELICA FOODS HOLDINGS CO.,LTD. All Rights Reserved</p>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
        </div>
      }
    </>
}

export default Footer;