import React from 'react';
import { Grid, } from '@mui/material';

interface Props {
    active?: number,
    isMobile: boolean,
}

export default function BreadCrumbs ({ active = 1, isMobile }: Props) {
    return (
        <>
            {isMobile ?
                <img 
                    src= {active === 1 ? '/breadcrumbs/step1-blue-sp.png' :
                        active === 2 ? '/breadcrumbs/step2-blue-sp.png' :
                        active === 3 ? '/breadcrumbs/step3-blue-sp.png' :
                        '/breadcrumbs/step4-blue-sp.png'}
                    style={{width: '100%', height: 'auto', minHeight: '30px', marginTop: '20px'}}
                />

            :
                <img 
                    src={active === 1 ? '/breadcrumbs/step1-blue.png' :
                        active === 2 ? '/breadcrumbs/step2-blue.png' :
                        active === 3 ? '/breadcrumbs/step3-blue.png' :
                        '/breadcrumbs/step4-blue.png'}
                    style={{width: '100%', height: 'auto', minHeight: '30px', marginTop: '20px'}}
                />

            }
        </>
    )
}